<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="getList()"
        ></el-button>
        <el-input
          size="small"
          placeholder="请输入客户编码/客户名称"
          v-model.trim="listQuery.customerName"
          style="width: 200px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getList"
          style="margin-right: 10px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form style="width: 400px" label-width="90px" size="small">
            <el-form-item label="收款日期：">
              <el-date-picker
                style="width: 150px"
                v-model="listQuery.receivableTime"
                type="date"
                placeholder="收款日期"
                clearable
                value-format="YYYY-MM-DD"
              />
              <span class="ml-5"> 前到期的应收款 </span>
            </el-form-item>
            <el-form-item label="审核状态：">
              <el-checkbox
                v-model="listQuery.auditStatus"
                :true-label="0"
                :false-label="2"
                >包含未审核通过单据</el-checkbox
              >
            </el-form-item>
          </el-form>
        </search>
        <span class="hint">
          逾期欠款{{ info.overdueNum }}户 总欠款{{ info.debtsAccounts }}元
          本月应收{{ info.monthNum }}户 总应收{{ info.monthAccounts }}元
          近期应收{{ info.recentNum }}户 总应收{{ info.recentAccounts }}元
        </span>
      </div>
      <div>
        <el-button size="small" type="primary" icon="Pointer" @click="charge"
          >收款</el-button
        >
        <!-- <el-button size="small" type="primary" icon="ChatDotSquare"
          >发送短信</el-button
        >
        <el-button size="small" type="success" icon="Folder">导出</el-button> -->
      </div>
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table
        v-loading="loading"
        :data="list"
        :scroll-y="{ enabled: true, gt: 0, mode: 'wheel' }"
        height="auto"
        style="width: 100%"
        border
        stripe
        auto-resize
        size="mini"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{ resizable: true }"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column
          align="center"
          field="sortColumn"
          type="checkbox"
          width="50"
        >
        </vxe-column>
        <vxe-column field="customerNum" title="客户编码" width="180">
          <template #default="scope">
            <span>{{ scope.row.customerNum }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerName" title="客户名称"> </vxe-column>
        <vxe-column field="monthAccounts" title="本月应收"> </vxe-column>
        <vxe-column field="overdueDebts" title="逾期欠款"> </vxe-column>
        <vxe-column field="recentReceivable" title="近期应收"> </vxe-column>
        <vxe-column field="monthPayment" title="本月收款"> </vxe-column>
      </vxe-table>
    </div>

    <div class="page_container">
      <qzf-pagination :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList()" />
    </div>
  </div>
  <chargeForm ref="chargeFormRef" :userOptions="userOptions" />
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { arrearsPaymentList, arrearsPaymentListSt } from "@/api/newCrm";
import chargeForm from "./components/chargeForm.vue";
import { getCurrentDate } from "@/utils/fun";
import { findPeople } from "@/api/base";
const { proxy } = getCurrentInstance();
const list = ref([]);
const total = ref(0);
const contentStyleObj = ref({});
const loading = ref(false);
const listQuery = ref({
  page: 1,
  limit: 20,
  auditStatus: 0,
  receivableTime: getCurrentDate(),
});
const chargeFormRef = ref();
const userOptions = ref([]);
const info = ref({});
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  findPeople({ code: "bookkeeping" }).then((res) => {
    userOptions.value = res.data.data.list;
  });
  getList();
  arrearsPaymentListStFunc()
});

function cancel() {
  listQuery.value = {
    page: 1,
    limit: 20,
    auditStatus: 0,
    receivableTime: getCurrentDate(),
  }
  getList()
}
const getList = () => {
  arrearsPaymentList(listQuery.value).then((res) => {
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
      // info.value = res.data.data.info;
    }
  });
};
function arrearsPaymentListStFunc() {
  arrearsPaymentListSt({
    receivableTime: getCurrentDate(),
  }).then((res) => {
    if (res.data.msg == "success") {
      info.value = res.data.data.info;
    }
  });
}
//收款登记
const charge = () => {
  chargeFormRef.value.init();
};
</script>
<script>
export default {
  name: "chargeStatistics",
};
</script>
<style scoped lang="scss">
.page_container {
  float: right;
  margin-top: 10px;
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:deep(.el-checkbox) {
  margin-right: 15px;
}
.filter-item {
  margin-right: 15px !important;
}
:deep(.el-dropdown-menu__item) {
  display: block;
}
.hint {
  margin-left: 5px;
  color: red;
}
</style>
