<template>
  <div class="app-container">

    <div style="margin-bottom:10px">
      <el-select v-model="listQuery.status" class="m-2" placeholder="请选择审核状态" size="small" style="margin-left: 0;">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <el-select v-model="listQuery.tradeState" class="m-2" placeholder="请选择交易状态" size="small">
        <el-option
          v-for="item in tradeStateOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <el-input v-model="listQuery.lxrdh" style="width:150px;margin-right: 10px;" placeholder="请输入联系电话" size="small"></el-input>
      <el-button @click="getList" type="primary" size="small" icon="Search">查询</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%;" border :height="contentStyleObj">
      <el-table-column prop="sqrlx" label="申请人类型" width="180" />
      <el-table-column prop="sslx" label="书式类型" width="180" />
      <el-table-column prop="dlrxm" label="代理人姓名" width="180"/>
      <el-table-column prop="dlwts" label="代理委托书" width="180"/>
      <el-table-column prop="sqrmc" label="申请人姓名" width="180"/>
      <el-table-column prop="tax_no" label="统一社会信用代码" width="220"/>
      <el-table-column prop="ztzgzmwj" label="主体资格证明文件(中文)" width="280"/>
      <el-table-column prop="sqrdz" label="申请人地址" width="200"/>
      <el-table-column prop="yzbm" label="邮政编码" width="180"/>
      <el-table-column prop="gnsqrlxdz" label="国内申请人联系地址" width="220"/>
      <el-table-column prop="gnsqryzbm" label="国内申请人邮政编码" width="220"/>
      <el-table-column prop="dzyx" label="国内申请人电子邮箱" width="220"/>
      <el-table-column prop="lxr" label="联系人" width="220"/>
      <el-table-column prop="lxrdh" label="联系人电话" width="220"/>
      <el-table-column prop="sblx" label="商标类型" width="220"/>
      <el-table-column prop="sfswbz" label="是否三维标志" width="220"/>
      <el-table-column prop="sfyszh" label="是否颜色组合" width="220"/>
      <el-table-column prop="sysb" label="声音商标" width="220"/>
      <el-table-column prop="sbmc" label="商标名称" width="220"/>
      <el-table-column prop="sbsm" label="商标说明" width="220"/>
      <el-table-column prop="sfgtsq" label="是否共同申请" width="220"/>
      <el-table-column prop="yxqsm" label="优先权声明" width="220"/>
      <el-table-column prop="spid" label="商品id" width="220"/>
      <el-table-column prop="tb" label="图标" width="220"/>
      <el-table-column prop="hbtb" label="黑白图标" width="220"/>
      <el-table-column prop="yxxzwsbsq" label="以肖像作为商标申请注册" width="220"/>
      <el-table-column prop="xxwj" label="肖像文件" width="220"/>
      <el-table-column prop="ygsmwj" label="有关说明文件" width="220"/>
      <el-table-column prop="outTradeNo" label="唯一单号" width="220"/>
      <el-table-column prop="tradeState" label="交易状态" width="220">
        <template #default="scope">
          <span v-if="scope.row.tradeState == 'SUCCESS'">支付成功</span>  
          <span v-if="scope.row.tradeState == 'REFUND'">转入退款</span>  
          <span v-if="scope.row.tradeState == 'NOTPAY'">未支付</span>
          <span v-if="scope.row.tradeState == 'CLOSED'">已关闭</span>
          <span v-if="scope.row.tradeState == 'REVOKED'">已撤销</span>
          <span v-if="scope.row.tradeState == 'USERPAYING'">用户支付中</span>
          <span v-if="scope.row.tradeState == 'PAYERROR'">支付失败</span>

        </template>
      </el-table-column>
      <el-table-column prop="total" label="订单总金额(单位为分)" width="220"/>
      <el-table-column prop="payerTotal" label="用户支付金额(单位为分)" width="220"/>
      <el-table-column prop="paySuccessTime" label="交易成功时间" width="220"/>
      <el-table-column prop="payUrl" label="交易二维码" width="220"/>
      <el-table-column prop="status" label="审核状态" width="220">
        <template #default="scope">
          <span v-if="scope.row.status == 1">已审核</span>  
          <span v-if="scope.row.status == 2">拒绝 </span>  
          <span v-if="scope.row.status == 9">未审核</span>  
        </template>
      </el-table-column>
      <el-table-column prop="sb_status" label="申报状态" width="220">
        <template #default="scope">
          <span v-if="scope.row.sb_status == 1">成功 </span>  
          <span v-if="scope.row.sb_status == 2">失败 </span>  
        </template>
      </el-table-column>
      <el-table-column prop="err_log" label="申报失败错误原因" width="220"/>
      <el-table-column prop="pdf" label="报成功pdf" width="220"/>
      <el-table-column align="center" prop="address" label="操作" min-width="100" >
        <template #default="scope">
          <el-button @click="handleUpdate(scope.row)" size="small" type="primary">
            <span> 审核</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { trademarkList ,trademarkExamine } from "@/api/crm";

export default {
  name: 'trademarkReview',

  data() {
    return {
      tableData:[],
      listQuery: {
        page: 1,
        limit: 20,
        lxrdh:'',
        tradeState:'',
        tradeStateOptions:''
      },
      total: 0,
      contentStyleObj:{}, //高度变化
      statusOptions:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'已审核'
        },
        {
          value:2,
          label:'拒绝'
        },
        {
          value:9,
          label:'未审核'
        },
      ],
      tradeStateOptions:[
        {
          value:'',
          label:'全部'
        },
        {
          value:'SUCCESS',
          label:'支付成功'
        },
        {
          value:'REFUND',
          label:'转入退款'
        },
        {
          value:'NOTPAY',
          label:'未支付'
        },
        {
          value:'CLOSED',
          label:'已关闭'
        },
        {
          value:'REVOKED',
          label:'已撤销（付款码支付）'
        },
        {
          value:'USERPAYING',
          label:'用户支付中（付款码支付）'
        },
        {
          value:'PAYERROR',
          label:'支付失败(其他原因，如银行返回失败)'
        }
      ]
    };
  },
  created(){
    this.getList()
    this.contentStyleObj=this.$getHeight(230)
  },
  mounted() {
    
  },

  methods: {
    getList(){
      trademarkList(this.listQuery).then(res => {
        if(res.data.data.list){
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.tableData = []
          this.total = 0
        }
        
      })
    },
    handleUpdate(row){
      trademarkExamine(row).then(res => {
        if(res.data.msg == 'success'){
          this.$qzfMessage("已提交审核")
          this.getList()
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>

</style>